@media screen and (max-width: 912px) {
    .career-banner-img {
        height: 180px;
    }
    .list-unstyled {
        display: none !important;
    }
}

.active {
    color: blue;
}

.career-form {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    max-height: 100vh;
    overflow-y: auto;
    width: 60vw;
    max-width: 800px;
}

.formClose {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    width: 20px;
    cursor: pointer;
}

@media screen and (max-width: 990px) {
    .career-form {
        width: 100%;
        top: 0%;
        left: 0%;
        transform: translateX(0);
        height: auto;
    }
}
