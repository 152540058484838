.team {
	margin-left: 2%;
	margin-right: 2%;
}

.member {
	float: left;
	width: 33%;
	padding: 0 10px;
}

#position {
	/* font-family: Inter; */
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 32px;
	color: #70757b;
}
.member-name #name {
	/* font-family: Inter; */
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 48px;
	color: #2c323c;
}
#quote {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 1.3rem;
	color: #92959a;
	padding: 0 15%;
}

/* Remove extra left and right margins, due to padding */
.team-members {
	/* margin: 0 -5px;
  margin-top: 79.56px; */
}

/* Clear floats after the columns */
.team-members:after {
	content: "";
	display: table;
	clear: both;
}
.ceo2 {
	width: 220px !important;
}
.card .image img {
	width: 200px;
	margin-bottom: 12px;
}

/* Responsive columns */
@media screen and (max-width: 1100px) {
	.member {
		width: 100%;
		display: block;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 576px) {
	#quote {
		padding: 0;
	}
}

/* Style the counter cards */
.card {
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
	border: none;
	padding: 16px;
	text-align: center;
	/* z-index: -1; */
	/* background-color: #f1f1f1; */
}
