.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loadingBar {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.loadingBar div {
  width: 32.5px;
  height: 46px;
  animation: eggMovement 1.5s linear infinite;
  position: absolute;
}

@keyframes eggMovement {
  0% {
    background-image: url("./assests/images/egg_grey.png");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 350px;
  }
  20% {
    background-image: url("./assests/images/egg_grey.png");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 335px;
  }
  40% {
    background-image: url("./assests/images/egg_grey.png");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 320px;
  }
  60% {
    background-image: url("./assests/images/egg_cracked.png");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 330px;
  }
  80% {
    background-image: url("./assests/images/egg_cracked.png");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 340px;
  }
  100% {
    background-image: url("./assests/images/egg_cracked.png");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 350px;
  }
}
