.navbar .nav-link {
  color: #3bb0a0;
  font-size: 1.1em;
  transition: all 0.2s;
}

.navbar .navbar-nav .nav-link:hover {
  color: #007464;
}

.navbar-logo-centered .navbar-nav .nav-link {
  padding: 0 1em;
}

.Nav-logo img {
  width: 85px;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 100;
  color: #fff;
  transition: all 0.2s;
  background: #fff;
  padding: 0.5rem 1rem;
}

.navTransparent {
  position: fixed;
  background: transparent;
  padding: 1.5rem 1rem;
}

.navTransparent .nav-link {
  color: #fff;
}

.navbgwhite {
  background-color: white;
  padding: 0.5rem 1rem;
  box-shadow: 0 0 1px 0.1px #70757b;
}

.navTransparent .Nav-logo {
  display: none;
}

.navbgwhite .Nav-logo {
  display: block;
}

.navbgwhite .navbar-nav .nav-link {
  color: #3bb0a0;
}

.navClose {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 22px;
  height: 22px;
  padding: 0%;
  cursor: pointer;
}

.menubar {
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .navbar .nav-link {
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media screen and (max-width: 990.5px) {
  .mobileNav {
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0%;
    left: 0%;
    transition: all 0.3s;
    display: block;
    width: 350px !important;
    max-width: 100%;
    overflow-y: auto;
    background: linear-gradient(to bottom, #e9f8f4, #fff);
    transform: translateX(-100%);
    cursor: pointer;
  }

  .showNav {
    transform: translateX(0%);
    box-shadow: inset 0 0 4px 1px #3bb0a0a7;
  }

  .navbar .nav-link {
    font-size: 1.2rem;
    color: #3bb0a0;
    padding: 0.8rem 0;
  }
}

@media screen and (max-width: 400px) {
  .mobileNav {
    width: 100% !important;
  }

  .Nav-logo img {
    width: 60px;
  }
}



