.global-column {
	/* float: left; */
	width: 300px;
	padding: 0 5px;
	position: relative;
	max-width: 100%;
}
.global-main {
	width: 100%;
	max-width: 100%;
}
.card-image {
	width: 100%;
	display: flex;
	justify-content: center;
}
.flag {
	/* position: relative; */
	background-repeat: no-repeat;
	padding-top: 120px;
	margin-bottom: -8px;
}

.map-img {
	max-width: 100%;
	position: absolute;
	left: -30px;
}
.bg-map {
	transition: all 1s;
	cursor: pointer;
	height: 120px;
	margin-bottom: 30px;
	width: 120px;
}
.bg-map:hover {
	transform: scale(1.3);
	transition: all 1s;
}

.global-row {
	display: flex;
    justify-content: space-evenly;
    padding: 1% 2%;
}

@media screen and (max-width: 950px) {}

/* Responsive columns */
@media screen and (max-width: 900px) {
	.global-column .global-card .flag-text {
		justify-content: center !important;
	}
	.global-column .global-card {
		text-align: center !important;
	}
	.global-row {
		flex-direction: column !important;
	}
	.global-column {
		display: block;
		margin-bottom: 20px !important;
		line-height: 0.5em;
	}

}

.global-column .global-card .flag-text img {
	width: 45px;
	height: 28px;
	margin-right: 5%;
}

.global-column .global-card h3 {
	color: #135b45;
	font-size: 1em;
}

.global-column .global-card p {
	color: #135b45;
	font-size: 14px;
	line-height: 22px;
	text-align: left;
}

.global-column .global-card .flag-text {
	font-weight: bold;
	color: #4b5159;
	text-align: left;
	display: flex;
	align-items: center;
}

@media screen and (max-width: 576px) {
	.global-column {
		width: 100%;
		padding: 0 20px;
	}

	.flag {
		background-position-x: 50%;
		text-align: center;
	}

	.flag * {
		justify-content: center;
	}
}
