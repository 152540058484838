#footer-main {
	padding: 10% 5% 100px 5%;
	background-color: white;
}
#footer-text {
	color: #70757b;
	font-size: 1em;
	line-height: 25px;
}

.footer-Image {
	width: 100px;
	display: block;
}

#footer-links {
	padding-top: 3%;
	text-align: left;
}

#footer-links li {
	padding-top: 15px;
	color: #70757b;
}
.footer-last {
	height: 15px;
	width: 100%;
	background: #007464;
}
.space-between {
	display: flex;
    justify-content: space-between;
}

.footer-social-icons {
	display: flex;
	gap: 1rem;
	justify-content: end;
}

.footer-social-icons img {
	width: 30px;
	height: 30px;
}

@media screen and (max-width: 990px) {
	.footer-social-icons {
		justify-content: center;
	}

	.footer-Image {
		width: 90px;
		display: inline-block;
	}
}

@media screen and (max-width: 576px) {
	.footer-Image {
		width: 80px;
		display: inline-block;
	}
}
