body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: inherit;
}

div .home {
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	background-position: center;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	transition: 3s;
}

:root {
	--primary-color: #37b0a1;
	--primary-head-color: #007464;
	--primary-text: #70757b;
	--light-blue: #e9f8f4;
}

.bg-primary-gradient {
	background: linear-gradient(to bottom, #e9f8f4, #fff);
}

.pr-text {
	color: var(--primary-text);
}

.pr-color {
	color: var(--primary-color);
}

.pr-color-bg {
	background: var(--primary-color) !important;
}

.pr-head-color {
	color: var(--primary-head-color);
}

.bg-gray {
	background-color: #e2e8dd;
}

.bg-trans {
	background-color: rgba(255, 255, 255, 0.3);
}

.hover-pr-green {
	transition: color 0.1s;
}

.hover-pr-green:hover {
	color: var(--primary-color);
}

.banner-heading {
	font-size: 2.4rem;
	font-weight: 700;
}

.section-head {
	text-align: center;
	will-change: transform, box-shadow, z-index;
}

.section-head h1 {
	font-size: 2em;
	color: rgba(0, 0, 0, 0.9);
}

.section-head h4 {
	font-size: 1em;
	color: grey;
}

@media screen and (max-width: 991px) {
	.banner-heading {
		font-size: 1.8rem;
		font-weight: 700;
	}
}

@media screen and (max-width: 576px) {
	html {
		font-size: 14px;
	}
}
