.company-awards{
    margin-top: 70px;
  }
  .award-heading{
    margin-left: 7%;
    margin-right: 7%;
    /* margin-right: 7%; */
  }
  #award-head{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
    /* or 150% */
    text-align: center;
    color: #4B5159;
  }

  #descp{
    padding-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #2C323C;
  }
  .award-main {
    float: left;
    width: 33%;
    padding: 0 30px;
  }
  
  /* Remove extra left and right margins, due to padding */
  .award-section1 {
    /* margin: 0 -5px; */
    margin-top: 3.5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  /* Clear floats after the columns */
  .award-section1:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive columns */
  @media screen and (max-width: 950px) {
    .award-main {
      width: 100%;
      display: block;
      /* margin-bottom: 20px; */
    }
  }
  .award-descp{
    text-align: center;
  }
  .award-card {
    padding: 16px;
    text-align: center;
  }
  
  .award-section2{
    margin-top: 2.5rem;
    margin-left: 5%;
    margin-right: 5%;
  }
  .award-section2:after {
    content: "";
    display: table;
    clear: both;
  }
 