.home-header {
  text-align: center;
  /* padding-top: 12%; */
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-header h1 {
  font-weight: 400;
  font-size: 3.2em;
}

.home-header h1 span {
  font-weight: 800;
}

.home-header .header-sub h6 {
  font-weight: inherit;
  font-size: 1.2em;
  width: 75%;
  padding-bottom: 50px;
}

.home-header .egg {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.home-header .egg img:hover {
  cursor: pointer;
}

.home-header .egg img {
  /* max-width: 1%; */
  width: 20px;
}

@media screen and (max-width: 576px) {
  .home-header h1 {
    font-weight: 500;
    font-size: 2em;
  }

  .home-header .header-sub h6 {
    width: 100%;
  }
}

.down-arrow {
  position: fixed;
  bottom: 2%;
  right: 2%;
  width: 3%;
  cursor: pointer;
  z-index: 1000000;
}

.up-arrow {
  position: fixed;
  bottom: 9%;
  right: 2%;
  width: 3%;
  cursor: pointer;
  z-index: 1000000;
  transform: rotate(180deg);
}

.scrollar {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.scrollar section {
  scroll-snap-align: start;
}