.sustainable-items {
    background: linear-gradient(to bottom, white, #e9f8f4);
}

.sustainable-image {
    height: 350px;
}
.bg-image {
    background-image: linear-gradient(rgb(0 0 0 / 38%), rgb(0 0 0 / 34%)),
        url("../../images/sustainability_banner.png");
    background-size: cover;
    color: white;
}
.mar-top-5 {
    margin-top: 7em;
    margin-bottom: 7em;
}
a {
    text-decoration: none;
}
.font-weight-bold {
    cursor: pointer;
}
a:hover {
    text-decoration: none;
}
.right-img {
    height: 500px;
}
.white {
    color: white !important;
}

.sustain-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}
.image-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sus-future-heading {
    padding-top: 130px;
    padding-bottom: 130px;
}


@media screen and (max-width: 991px) {
    .product-items .item {
        width: 100%;
        padding: 0;
        margin: auto;
    }

    .right-img {
        position: relative;
        height: auto;
        width: 100%;
    }

    .sus-future-heading {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media screen and (max-width: 576px) {
    .product-items .item {
        width: 220px;
    }

    .sustainable-image {
        height: 250px;
    }
}

@media screen and (max-width: 768px) {
    .right-img {
        height: 250px;
        width: auto;
    }

    .left-banner-section {
        padding-bottom: 30px;
    }
}
