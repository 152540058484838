.h {
	margin-top: 70px;
}
.d p {
	font-weight: normal;
	font-size: 18px;
	line-height: 32px;
	text-align: center;
	letter-spacing: -0.01em;
	color: #70757b;
	margin-bottom: 125px;
}

body {
	overflow-x: hidden;
}
.timeline-main,
.timeline-container > header {
    width: 95%;
    max-width: 72em;
    margin: 0 auto;
    padding: 0 0.875em 1.125em 1.875em;
    height: 82vh;
}

.timeline {
    height: 75vh;
    overflow-y: auto;
    padding-right: 3vw;
}

.cbp_tmtimeline {
	margin: 15px 0 0 0;
	padding: 0;
	list-style: none;
	position: relative;
}

.timeline::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

.timeline::-webkit-scrollbar {
	width: 10px;
	background-color: #f5f5f5;
}

.timeline::-webkit-scrollbar-thumb {
	background-color: #007464;
	border-radius: 5px;
}

/* The line */
.cbp_tmtimeline:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: 6px;
	background: #3bb0a0;
	left: calc(25% + 48px);
	margin-left: -10px;
}

/* The date/time */
.cbp_tmtimeline > li {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.cbp_tmtimeline > li .cbp_tmtime {
	display: block;
	width: 25%;
	/* padding-right: 100px; */
	/* position: absolute; */
}

.cbp_tmtimeline > li .cbp_tmtime span {
	display: block;
	/* text-align: right; */
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
	margin-top: 15px;
	font-weight: bold;
	font-size: 2em;
	color: #007464;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
	margin-top: 15px;
	font-weight: bold;
	color: #3bb0a0;
}

/* Right content */
.cbp_tmtimeline > li .cbp_tmlabel {
	/* margin: 0 0 70px 50%; */
	background: #007464;
	flex: 1;
	color: #fff;
	padding: 1rem 2em;
	font-size: 1em;
	font-weight: 300;
	line-height: 1.4;
	position: relative;
	border-radius: 30px;
	min-height: 75px;
	display: flex;
	align-items: center;
}

.cbp_tmtimeline > li:nth-last-child(1) {
	margin-bottom: 0%;
}

.cbp_tmtimeline > li .cbp_tmlabel p {
	margin-bottom: 0%;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
	background: #eff9f8;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 {
	margin-top: 0px;
	padding: 0 0 10px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel p {
	line-height: 30px;
	color: #007464;
	font-weight: bold;
	font-size: 18px;
}
.cbp_tmtimeline > li .cbp_tmlabel p {
	line-height: 30px;
	color: #eff9f8;
	font-weight: bold;
	font-size: 18px;
}
/* The triangle */
.cbp_tmtimeline > li .cbp_tmlabel:after {
	right: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-right-color: #007464;
	border-width: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
	border-right-color: #eff9f8;
}

/* The icons */
.cbp_tmtimeline > li .cbp_tmicon {
	width: 80px;
	height: 80px;
	margin-right: 100px;
	font-family: "ecoico";
	/* speak: none; */
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 1.4em;
	line-height: 40px;
	-webkit-font-smoothing: antialiased;
	/* position: absolute; */
	color: #fff;
	background: #eff9f8;

	background-position: center;
	background-repeat: no-repeat;
	border-radius: 50%;
	/* box-shadow: 0 0 0 8px #afdcf8; */
	border: 6px solid #3bb0a0;
	text-align: center;
	/* left: 30%;
	top: 0; */
	/* margin: 0 0 0 -25px; */
}
.bank-icon {
	width: 50px;
	height: 50px;
	margin-top: 18px;
	margin-right: 15px;
}
.cbp_tmicon-screen:before {
	content: "\e001";
}

.cbp_tmicon-mail:before {
	content: "\e002";
}

.cbp_tmicon-earth:before {
	content: "\e003";
}

/* Example Media Queries */
@media screen and (max-width: 1100px) {
	.cbp_tmtimeline:before {
		/* left: 33%; */
	}
}
@media screen and (max-width: 65.375em) {
	.cbp_tmtimeline > li .cbp_tmtime span:last-child {
		font-size: 1.5em;
	}
	.cbp_tmtimeline:before {
		/* left: 34%; */
	}
}

@media screen and (max-width: 47.2em) {
	.cbp_tmtimeline:before {
		display: none;
	}

	.cbp_tmtimeline > li {
		display: block;
	}

	.cbp_tmtimeline > li .cbp_tmtime {
		width: 100%;
		position: relative;
		padding: 0 0 20px 0;
	}

	.cbp_tmtimeline > li .cbp_tmtime span {
		text-align: left;
	}

	.cbp_tmtimeline > li .cbp_tmlabel {
		margin: 0 0 30px 0;
		padding: 1em;
		font-weight: 400;
		font-size: 95%;
	}

	.cbp_tmtimeline > li .cbp_tmlabel:after {
		right: auto;
		left: 25px;
		border-right-color: transparent;
		border-bottom-color: #007464;
		top: -65px;
	}

	.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
		/* left: 3px; */
		border-right-color: transparent;
		border-bottom-color: #eff9f8;
		margin-top: 45px;
	}

	.cbp_tmtimeline > li .cbp_tmicon {
		background-size: 30px;
		width: 50px;
		height: 50px;
		position: relative;
		float: right;
		left: auto;
		margin: -55px 5px 0 0px;
	}

	.timeline-main,
	.timeline-container > header {
		width: 100%;
	}

	.timeline {
		padding-right: 0;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	.timeline::-webkit-scrollbar {
		display: none;
	}
}
