/* .header{
    background: linear-gradient(to right , #E9F8F4,#fff);
} */

a{
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.left-banner-section{
    padding-bottom: 100px;
}

.allproductsBtn{
    font-weight: 700;
    font-size: 0.9rem;
}

.ingredients h6{
font-size: 1.15rem;
}

.enquireBtn{
    background-color: #F2FBF9;
    border: none;
    transition: all 0.2s;
    width: max-content;
    max-height: 12rem;
}

.enquireBtn:hover{
    background-color: #3bb0a0;
    color: white;
    border: 1px solid white;
}

.product-detail-img{
    height: 300px;
    max-width: 300px;
    bottom: 0;
}

.greyheading{
    color: #4B5159;
    min-height: 55px;
}

.product-items{
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-items .item{
    width: 250px;
    padding: 0;
    margin: auto;
}
.d-flex-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-items .item .item-img{
    background: linear-gradient(to bottom, #E9F8F4, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.8rem 0;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.2s;
}
.product-items .item .item-img img{
    height: 200px;
    max-width: 100%;
    transition: all 0.2s;
}

.product-items .item .item-img:hover img{
    transform: scale(1.3);
}

.product-items a{
    text-decoration: none;
    color: #3bb0a0;
    font-size: medium;
    transition: all 0.2s;
}

.product-items a:hover {
   letter-spacing: 1.2px;
}

@media screen and (max-width:991px){
    .product-items .item {
            width: 100%;
            padding: 0;
            margin: auto;
    }

    .product-detail-img {
        height: 350px;
    }
}

@media screen and (max-width:576px) {
    .product-items .item {
        width: 220px;
    }

    .product-detail-img{
        height: auto;
    }

    
}

@media screen and (max-width:768px) {
     .right-img {
            height: 250px;
            width: auto;
        }

        .left-banner-section {
            padding-bottom: 30px;
        }
}