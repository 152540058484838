.MainSlider .slick-prev{
    left: 20px ;
}
.MainSlider .slick-next {
    right: 20px ;
}

.MainSlider .slick-prev,.MainSlider .slick-next{
    height: 30px;
    width: 32px;
    z-index: 1;
}

.MainSlide{
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover !important;
    padding-top: 150px;
    padding-bottom: 150px;
}

.d-flex-center {
    height: 100vh;
    display: flex;
    align-items: center;
}


@media screen and (max-width:576px) {
    .MainSlider .slick-prev {
            left: 5px;
        }
    
        .MainSlider .slick-next {
            right: 5px;
        }
    
        .MainSlider .slick-prev,
        .MainSlider .slick-next {
            height: 20px;
            width: 20px;
            z-index: 1;
        }
    
        .MainSlide {
            padding: 70px 1.5rem;
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
}