.App {
	text-align: center;
}

.Map-center {
	display: flex;
    justify-content: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.contact-header h1 {
	font-size: 2.5rem;
	font-weight: 700;
}

.contact-rounded {
	border-style: none;
	width: 50%;
	background: #f4f4f5;
	line-height: 3em;
	outline: none;
}

.contact-searchicon {
	padding-left: 2%;
	border-style: none;
	background: #f4f4f5;
	color: #70757b;
	line-height: 70%;
	font-size: 1.5em;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
	padding-top: 10px;
	padding-bottom: 15px;
}

.contact-search {
	line-height: 3em;
	border-style: none;
	background: #3bb0a0;
	padding-left: 2%;
	padding-right: 2%;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: unset;
	border-top-left-radius: unset;
	color: #ffffff;
	padding-bottom: 1px;
	padding-top: 0px;
}

.contact-tab {
	margin-top: 10%;
	width: 100%;
	text-align: center;
}

.contact-images {
	width: 33%;
	text-align: center;
}

.contact-text {
	color: #70757b;
	font-style: normal;
}

.contact-text2 {
	padding-left: 12%;
	padding-right: 12%;
}

.contact-title3 {
	color: #4b5159;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

/* Remove extra left and right margins, due to padding */
.contact-row {
	margin: 0 -5px;
	display: flex;
}

/* Clear floats after the columns */
.contact-row:after {
	content: "";
	display: table;
	clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
	.contact-column {
		width: 100%;
		display: block;
		margin-bottom: 0px;
	}

	.contact-row {
		display: block;
	}

	.contact-desc {
		padding-left: 0;
		padding-right: 0;
	}
}

/* Style the counter cards */
.contact-card {
	padding: 16px;
	text-align: center;
	background-color: white;
	color: #70757b;
}

.contact-card img {
	width: 60px;
	height: 60px;
}

.contact-head-desc {
	color: #70757b;
	margin-top: 1%;
	margin-bottom: 5%;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 32px;
}

.contact-desc {
	padding-left: 15%;
	padding-right: 15%;
	color: #70757b;
	margin-top: 5%;
	font-size: 1.2rem;
	line-height: 30px;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 768px) {
	.contact-desc {
		padding-left: 5%;
		padding-right: 5%;
	}
}

@media screen and (max-width: 576px) {
	.contact-rounded {
		flex: 1;
	}

	.contact-searchicon {
		padding-left: 10px;
	}

	.contact-search {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.contact-card img {
		width: 50px;
		height: 50px;
	}
}
