.parivar-head {
  margin-top: 5%;
}

.parivar .slick-slider {
  padding-bottom: 10px;
  min-height: 90vh;
}

.parivar .slick-prev,
.parivar .slick-next {
  width: 24px;
  height: 24px;
}

.parivar .slick-prev {
  left: 8vw;
}

.parivar .slick-next {
  right: 8vw;
}

.MainSlider {
  height: calc(100vh - 85px);
  background: rgb(239, 249, 248);
  padding-top: 3.5%;
}

@media screen and (max-width: 767px) {
  .MainSlider {
    padding-top: unset !important ;
  }
  .parivar .slick-prev,
  .parivar .slick-next {
    width: 17px;
    height: 17px;
  }

  .parivar .slick-prev {
    left: 15px;
  }

  .parivar .slick-next {
    right: 15px;
  }
}

.parivar .slick-dots {
  bottom: 30px;
}

.parivar .slick-dots li button:before {
  font-size: 12px;
}

.parivar .slick-dots .slick-active button:before {
  color: #007464;
}

/* .carousel {
  background-color: #eff9f8;
  height: 80vh;
} */

.d-block-m-auto {
  display: block !important;
  margin: auto !important;
}
.slick-dots {
  margin-bottom: 35px;
}
.slick-dots li button:before {
  font-size: 13px;
}

.carousel-heading {
  padding-top: 1%;
  text-align: center;
}
.carousel-heading p {
  font-size: 1.5em;
  color: #000;
}
.carousel-description {
  margin-top: 7%;
  margin-bottom: 7%;
  display: flex;
}
.carousel-description img {
  width: 200px;
  height: 200px;
  margin-top: 3%;
  border-radius: 50%;
  margin: auto;
}
.carousel-sub-description {
  color: #37b0a1;
}
.sub-text {
  margin-top: 5%;
}
.sub-text p {
  font-size: 1.2em;
  line-height: 30px;
  color: #70757b;
}
.stats {
  display: flex;
  margin-top: 5%;
  justify-content: space-between;
}
.stats div h1 {
  text-align: center;
  color: #007464;
}
.stats div h4 {
  color: grey;
}
.carousel-indicators [data-bs-target] {
  background-color: #37b0a1;
  border-radius: 50%;
  height: 7px;
  width: 7px;
}

.ParivarCorousel {
  background-color: #eff9f8;
}
