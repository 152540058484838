.sustainable {
	background-color: #eff9f8;
	padding: 4rem 10%;
	display: flex;
}

.sustain-heading {
	width: 50%;
}

.sustain-heading p {
	margin-top: 5%;
	color: grey;
	font-size: 1em;
	line-height: 25px;
	width: 75%;
}
.sustain-button {
	padding: 10px 20px;
	border-radius: 8px;
	margin-top: 3%;
	background-color: #3bb0a0;
}

.sustain-right {
	display: flex;
	justify-content: space-between;
	width: 70%;
}

.biogas {
	width: 50%;
	height: 500px;
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../images/Biogas.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 20px;
	margin-right: 40px;
	/* box-shadow: 5px 10px grey; */
	cursor: pointer;
}
.fertilizer {
	width: 51%;
	height: 500px;
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../images/Manure.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 20px;
	margin-right: 40px;
	/* box-shadow: 5px 10px grey; */
	cursor: pointer;
}

.sustain-right-head {
	margin-top: 15%;
	margin-left: 5%;
	margin-right: 5%;
	padding-bottom: 60px;
	color: white;
}

.sustain-right-head h3 {
	font-size: 2em;
	font-weight: bold;
}

@media screen and (max-width: 915px) {
	.carousel-description img {
		width: 150px !important;
		height: 150px !important;
	}
	.sub-text p {
		font-size: 1em !important;
		line-height: 1.1em !important;
	}
	.stats h2 {
		font-size: 1.1em !important;
	}
	.stats h4 {
		font-size: 1em !important;
	}
	
	.sustainable {
		display: flex;
		flex-flow: column;
		height: auto;
	}

	.sustain-heading {
		display: flex;
		flex-flow: column;
		width: auto;
	}

	.fertilizer {
		margin-top: 10vh;
		padding-bottom: 40px;
		width: fit-content;
		height: auto;
	}
	.biogas {
		width: fit-content;
		padding-bottom: 40px;
		height: auto;
	}

	.sustain-heading p {
		width: 100%;
	}

	.sustain-right {
		display: flex;
		flex-flow: row;
		padding-top: 0px;
		width: 100%;
		margin: auto;
		text-align: center;
		justify-content: center;
		margin-top: 70px;
	}
	.fertilizer {
		margin-top: 0px;
	}

	.sustain-right-head h3 {
		width: 100%;
	}

	.sustain-button {
		width: fit-content;
		padding: 5px 15px 5px 15px;
		height: 20%;
		font-size: 90%;
	}
}

@media screen and (max-width: 500px) {
	.sustain-right {
		display: flex;
		flex-flow: column;
	}

	.biogas {
		width: 100%;
	}
	.fertilizer {
		margin-top: 10vh;
		width: 100%;
	}
	.sustainable {
		height: fit-content;
	}
}

@media screen and (max-width: 300px) {
	.sustainable {
		height: fit-content;
	}
}
.parivar-head {
	margin-top: 5%;
}

.parivar .slick-slider {
	padding-bottom: 10px;
	min-height: 90vh;
}

.parivar .slick-prev,
.parivar .slick-next {
	width: 24px;
	height: 24px;
}

.parivar .slick-prev {
	left: 8vw;
}

.parivar .slick-next {
	right: 8vw;
}

@media screen and (max-width: 576px) {
	.parivar .slick-prev,
	.parivar .slick-next {
		width: 17px;
		height: 17px;
	}

	.parivar .slick-prev {
		left: 15px;
	}

	.parivar .slick-next {
		right: 15px;
	}
}

.parivar .slick-dots {
	bottom: 30px;
}

.parivar .slick-dots li button:before {
	font-size: 12px;
}

.parivar .slick-dots .slick-active button:before {
	color: #007464;
}

/* .carousel {
	background-color: #eff9f8;
	height: 80vh;
  } */

.d-block-m-auto {
	display: block !important;
	margin: auto !important;
}
.slick-dots {
	margin-bottom: 35px;
}
.slick-dots li button:before {
	font-size: 13px;
}

.carousel-heading {
	padding-top: 1%;
	text-align: center;
}
.carousel-heading p {
	font-size: 1.5em;
	color: #000;
}
.carousel-description {
	margin-top: 7%;
	margin-bottom: 7%;
	display: flex;
}
.carousel-description img {
	width: 200px;
	height: 200px;
	margin-top: 3%;
	border-radius: 50%;
	margin: auto;
}
.carousel-sub-description {
	color: #37b0a1;
}
.sub-text {
	margin-top: 5%;
}
.sub-text p {
	font-size: 1.2em;
	line-height: 30px;
	color: #70757b;
}
.stats {
	display: flex;
	margin-top: 5%;
	justify-content: space-between;
}
.stats div h1 {
	text-align: center;
	color: #007464;
}
.stats div h4 {
	color: grey;
}
.carousel-indicators [data-bs-target] {
	background-color: #37b0a1;
	border-radius: 50%;
	height: 7px;
	width: 7px;
}

.ParivarCorousel {
	background-color: #eff9f8;
}
