@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #333333;
}
h1,
p {
    margin-top: 0;
}
h1 {
    margin-bottom: 10px;
}
h1 + p {
    color: #999999;
    margin-bottom: 30px;
}
p {
    margin-bottom: 20px;
}
a {
    color: #0086e4;
    text-decoration: none;
}
input[type="checkbox"] {
    margin: 0;
    padding: 0;
    height: 17px;
}
.wrapper {
    min-height: 6em;
    display: grid;
    place-items: center;
}
.container2 {
    width: 100%;
    height: auto;
    max-width: 70%;
    min-width: 30%;
    background-color: white;
}

.tabs {
    display: flex;
    justify-content: space-between;
}
.tabs__text {
    flex: 1;
    margin: 0;
    cursor: pointer;
    padding: 20px 30px;
    font-size: 1.2em;
    opacity: 0.5;
    background-color: #eeeeee;
    border-top: 3px solid #eeeeee;
    transition: border-top 300ms ease-out;
    transform-origin: top;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}
.tabs__content {
    display: none;
    flex: 1 1 1 100%;
    order: 99;
    padding: 40px 30px 30px 30px;
}
.tabs__button {
    visibility: hidden;
    height: 0;
    margin: 0;
    position: absolute;
}
.tabs__button:checked + .tabs__text {
    color: #0086e4;
    opacity: 1;
    background-color: white;
    border-top: 3px solid #0086e4;
}
.tabs__button:checked + .tabs__text + .tabs__content {
    display: block;
}
@media screen and (max-width: 912px) {
    .tabs__text {
        padding: 10px 13px !important;
        font-size: .75em !important;
    }
}