.business .section-head {
	margin-top: 80px;
}

.image_box {
	overflow: hidden;
	/* padding: 100px 0; */
	margin: 50px 0;
	min-height: 73vh;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: -1;
}

.box {
	margin: 5px;
	width: 20%;
	height: 60vh;
	border: 1px solid black;
	border-radius: 15px;
	box-shadow: 2px 10px 12px rgba (0, 0, 0, 0.5);
	transition: 0.4s;
	overflow: hidden;
}

.box img {
	width: 100%;
	height: 100%;
	z-index: -1;
	object-fit: cover;
}

.business-head h1,
.box div h4 {
	color: white;
}

.business .box p {
    font-size: 100%;
    line-height: 19px;
    color: white;
}

#first,
#last {
	height: 54vh;
	overflow: hidden;
}

#hover {
	transition: 0.4s;
	height: 68vh;
}

#second {
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		url("../../images/ourbusiness/feeds.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
#secondlast {
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		url("../../images/ourbusiness/food.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.mid {
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		url("../../images/ourbusiness/hatchery.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
#first {
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		url("../../images/ourbusiness/equipments.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

#last {
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		url("../../images/ourbusiness/protein.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
#second:hover,
#secondlast:hover {
	transition: 0.4s;
	height: 68vh;
}

#first:hover {
	transition: 0.4s;
	height: 68vh;
}

#last:hover {
	transition: 0.4s;
	height: 68vh;
}
.box div {
	padding: 4%;
}

.box button {
	border: 1px solid white;
	margin-top: 2%;
	text-align: center;
	background-color: transparent;
	padding: 2% 5%;
	color: white;
}

@media screen and (max-height: 800px) {
	#first,
	#last {
		height: 46vh;
		overflow: hidden;
	}

	.box {
		height: 53vh;
	}

	#hover {
		transition: 0.4s;
		height: 60vh;
	}

	#second:hover,
	#secondlast:hover {
		height: 60vh;
	}

	#first:hover {
		height: 60vh;
	}

	#last:hover {
		height: 60vh;
	}

	.image_box {
		margin: 0px 0;
	}
}

@media screen and (max-width: 576px) {
	.business .section-head {
		top: 0%;
		margin-top: 30px;
	}
}

@media screen and (max-width: 990px) {
	.image_box {
		overflow: auto;
		justify-content: start;
		margin: 50px 0;
	}

	/* // Hide scrollbar for Chrome, Safari and Opera */
	.image_box::-webkit-scrollbar {
		display: none;
	}

	/* // Hide scrollbar for IE, Edge and Firefox */
	.image_box {
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	.image_box .box {
		min-width: 250px;
	}
}

@media screen and (max-width: 990px) {
	.image_box {
		overflow: auto;
		justify-content: start;
		min-height: initial !important;
		/* padding: 50px 0; */
	}

	.image_box .box {
		min-width: 200px !important;
		height: 46vh !important;
	}
}
