.director {
  /* background-color: #e5e5e5; */
  width: 100%;
  padding: 0% 9vw;
  padding-bottom: 5%;
}

.director-heading {
  text-align: center;
  padding: 5%;
}

.director-content {
  display: flex;
  justify-content: space-between;
}
.director-left {
  width: 55%;
  position: relative;
}
.director-left h2 {
  color: #37b0a1;
  font-weight: bold;
}

.director-left h4 {
  color: #4b5159;
  font-weight: 600;
  padding-bottom: 20px;
}

.director-left p {
  color: #70757b;
}

.director-right img {
  height: 450px;
}

.quote{
  position: absolute;
    left: -9vw;
    width: 100px;
    bottom: -10px;
}

@media screen and (max-width: 950px) {


  .director-content {
  
    display: flex;
    flex-flow: column;
  }

  .director-right{
    order: 1;
    width: 100%;
    text-align: center;
  }

  .director-right img{
    width: 52vw;
    height: 60vw;
  }

  .director-left{
    order: 2;
    width: 100%;
    text-align: left;
    margin-top: 30px;
  }

  .director-left h2 {
    text-align: center;
  }

  .director-left h4 {
    text-align: center;
  }
}