.about-header {
  min-height: 48vh;
  background-size: cover;
  background-attachment: fixed;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../images/aboutbanner.png");
  padding-right: 3.5rem;
}

.right-heading {
  font-size: 4em !important;
  position: absolute;
  right: 1em;
  bottom: 0px;
  font-weight: 350;
  margin-bottom: -18px;
}
.text-start {
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .right-heading {
    font-size: 2.5rem !important;
    right: 0.5em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
}

@media only screen and (min-width: 767px) {
  .typewrite {
    animation: typing 4s steps(45) 1s 1 normal both, blinkTextCursor 500ms steps(45) normal;
  }
  .line-1 {
    position: absolute;
    width: 6.2em;
    right: 2em;
    margin: 0 auto;
    border-right: 2px solid rgba(255, 255, 255, 0.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 6.2em;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}


