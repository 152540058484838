.product-section a {
    color: inherit;
}

.product-section a:hover {
    color: inherit;
}

.category-items img {
    width: 45px;
}

.category-items > div {
    cursor: pointer;
}

.category-items .category {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.category-items .category * {
    pointer-events: none;
}

.category-items .category img {
    filter: grayscale(90%);
    opacity: 0.7;
}

.category-items .active-category img {
    filter: grayscale(0%);
    opacity: 1;
}

.category-items .active-category span {
    color: #007464;
}

.product-section .slick-arrow.slick-prev,
.product-section .slick-arrow.slick-next {
    top: -3.5rem;
    width: 16px;
    height: 16px;
}

.product-section .slick-arrow.slick-prev {
    left: unset;
    right: 60px;
}

.product-section .slick-arrow.slick-next {
    right: 30px;
}

.product-section .centerProducts .slick-slide {
    width: 25% !important;
}

.product-section .centerProducts .slick-track {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 990px) {
    .product-section .centerProducts .slick-slide {
        width: 33% !important;
    }

    .product-cart {
        width: 90% !important;
    }
    .productCart {
        margin-top: 6em !important;
    }
}

.product-head {
    text-align: center;
    justify-content: center;
    margin: 40px auto auto auto;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
}

.productcart-button {
    text-align: left;
}

.product-cart {
    width: 80%;
    background: #ffffff;
    border: 1px solid #3bb0a0;
    text-align: left;
    box-sizing: border-box;
    border-radius: 20px;
    margin: auto;
    padding-top: 15px;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    overflow: visible;
    cursor: pointer;
}

.product-cart div img {
    border-radius: 10px;
    margin: auto;
}

.product-cart .product_img {
    background: linear-gradient(to bottom, #e9f8f4, #fff);
    width: 92%;
    margin: auto;
    border-radius: 10px;
}

.product-cart .product_img img {
    height: 180px;
    max-width: 100%;
    transition: transform 0.3s;
}
.mn-h-3 {
    min-height: 3em;
}

.product-cart .product_img img:hover {
    transform: scale(1.18);
}

.slick-list {
    overflow-y: visible !important;
}

.product-cart_desc {
    height: 95px;
    overflow: hidden;
}

.backToTop {
    cursor: pointer;
}

.backToTop img {
    width: 20px;
}
.oy {
    overflow-y: scroll;
}
/* ::-webkit-scrollbar {
    width: 5px;
  } */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
    */
/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #888; 
  }
   */
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */

@media only screen and (max-width: 790px) {
    .product-row1 {
        margin: 0px;
    }
    .product {
        /* word-wrap: break-word; */
        width: 100vw;
    }
}

@media only screen and (max-width: 600px) {
    .product {
        /* word-wrap: break-word; */
        width: 100vw;
    }
    .product-cart {
        width: 200px !important;
        margin: 10px auto;
    }

    .category-items img {
        width: 30px;
    }

    .product-section .slick-arrow.slick-prev,
    .product-section .slick-arrow.slick-next {
        /* top: -25px; */
        width: 18px;
        height: 18px;
    }
}

@media screen and (max-width: 576px) {
    .product-section .slick-arrow.slick-prev,
    .product-section .slick-arrow.slick-next {
        top: -30px;
    }

    .product-cart_desc {
        height: 55px;
    }
}

/* enquiryForm */

.enquiryForm form button[type="submit"] span {
    padding: 12px 8px;
}

.enquiryForm form input,
.enquiryForm form textarea {
    border-radius: 8px;
}

@media screen and (max-width: 450px) {
    .product-cart {
        width: 90% !important;
        max-width: 280px !important;
    }
}
